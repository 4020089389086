import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { useCookies } from "react-cookie";
import Pl from "./../../assets/images/svg/pl.svg"
import En from "./../../assets/images/svg/en.svg"

const Link = styled.a`
  width: 30px;
  height: 30px;
`

const LanguageSwitcherContainer = styled.div`
  display: flex;
  gap: 2rem;

  svg {
    width: 100%;
    height: 100%;
    transition: all 200ms ease;
  
    &:hover {
      transform: rotate(20deg);
    }
  }
`

const LanguageSwitcher = ({ pageContext }) => {
  const [cookies, setCookie] = useCookies(["language"]);
  const [translationUrl, setTranslationUrl] = useState('')

  useEffect(() => {
    const pathLanguagePart = window.location.pathname.slice(0, 4)
    let tempTranslationUrl = '';

    if (pathLanguagePart.includes('/pl/')) {
        setCookie("language", "pl", { path: "/" });
    } else {
        setCookie("language", "en", { path: "/" });
    }
    
    
    tempTranslationUrl = cookies.language === "pl" ? tempTranslationUrl = '/' : tempTranslationUrl = 'pl'
    if (pageContext && pageContext.translations.length > 0)
      tempTranslationUrl = pageContext.translations[0].uri === '/pl/strona-glowna/' ? '/pl/' : pageContext.translations[0].uri
  
    if (pageContext && pageContext.contentType === 'post') {
      if (pageContext.translations.length > 0) {
        pathLanguagePart.includes('/pl/') ? tempTranslationUrl = `/blog/${pageContext.translations[0].uri}` : tempTranslationUrl = `/pl/wpisy/${pageContext.translations[0].slug}`
      } else
        pathLanguagePart.includes('/pl/') ? tempTranslationUrl = `/blog/` : tempTranslationUrl = `/pl/wpisy/`
    }

    setTranslationUrl(tempTranslationUrl)
  }, [cookies, setCookie]);

  const handleLanguageChange = (language) => {
    setCookie("language", language, { path: "/" });
  };

  return (
    <LanguageSwitcherContainer>
      {cookies.language === "en" ?
        <Link
          href={translationUrl}
          onClick={() => handleLanguageChange("pl")}
        >
          <Pl />
        </Link>
      :
        <Link
          href={translationUrl}
          onClick={() => handleLanguageChange("en")}
        >
          <En />
        </Link>
      }
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcher;