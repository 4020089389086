import { createGlobalStyle } from "styled-components"

import { maxD } from "../helpers/devices"
import { noAppearance } from "../helpers/common"

const Normalize = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    
    *::before,
    *::after {
        pointer-events: none;
    }

    :root {
        font-weight: 500;
        font-size: 16px;

        @media ${maxD.laptop} {
            font-size: 15px;
        } 
        @media ${maxD.mobileL} {
            font-size: 14px;
        } 
    }

    html {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        
        touch-action: manipulation;

        overflow-x: hidden;
        scroll-behavior: smooth;

        font-size: 80%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        width: 100vw;
    }

    body {
        margin: 0;
        position: relative;
        max-width: 100vw;

        
        line-height: 1.78;
        color: var(--dark-gray);
    }

    body,
    button,
    input,
    textarea,
    select {
        font-family: var(--sans-serif)
    }

    input,
    textarea {
        font-size: 0.8rem;
        font-weight: 600;
        background-color: #fff;
        
        &::placeholder {
            opacity: 1;
            color: var(--dark-gray);
        }
    }

    input,
    textarea,
    select {
        ${noAppearance}
        min-width: 0;
    }

    a {
        color: var(--dark-gray);
        text-decoration: none;
    }

    *::selection {
        background-color: var(--main-green);
        color: #fff;

        -webkit-text-fill-color: initial;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.25;
        font-weight: 600;
    }

    h1,
    h2 {
        line-height: 1.22;
    }

    h3,
    h4,
    h5,
    h6 {
        line-height: 1.3;
    }

    p,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    figure,
    figcaption {
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        list-style: none;
        
        padding-left: var(--left-padding, 40px);
        padding-bottom: var(--bottom-padding, 30px);

        @media ${maxD.mobileL} {
            padding-left: calc(var(--left-padding, 40px) * 0.7);
            padding-bottom: calc(var(--bottom-padding, 30px) * 0.7);
        }

        &::before {
            display: block;
            position: absolute;

            left: 0;
        }
    }

    ol {
        counter-reset: counter;

        li {
            &::before {
                counter-increment: counter;
                content: counter(counter) ". ";

                color: var(--main-green);
                font-weight: 600;
            }
        } 
    }

    ul {
       li {
            --dot-size: 10px;

            &::before {
                content: var(--dots-appearance, "");

                width: var(--dot-size);
                height: var(--dot-size);
                top: 0.55rem;

                background-color: var(--main-green);
            }
       } 
    }

    button {
        background-color: transparent;

        padding: 0;

        &:hover {
            cursor: pointer;
        }
    }

    button,
    select {
        border: none;
    }

    blockquote {
        margin: 0;
    }

    cite,
    address {
        font-style: normal;
    }

    svg {
        display: block;
    }

    strong,
    b {
        font-weight: 500;
    }

    *:focus {
        outline: none;
    }

    body.user-is-tabbing *:focus {
        outline: 2px solid var(--main-green);
    }

    figure, 
    section,
    article,
    summary,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        scroll-margin-top: 150px;

    }

    figure, 
    section,
    article,
    summary {
        position: relative;
        z-index: 1;
    }

    summary {
        list-style-type: none;
        
        &::-webkit-details-marker {
            display: none;
        }
    }
`

export default Normalize
