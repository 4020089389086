import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import useStickyElement from "../../../hooks/useStickyElement"

import { maxD } from "../../../assets/styles/helpers/devices"
import { PageWithoutScroll } from "../../../assets/styles/helpers/modificators"

import { WideWrapper } from "../common/containers"
import { Button } from "../atoms/Button"
import { UnderlinedLink } from "../common/links"
import { HamburgerMenu } from "./Hamburger"
import { Brand } from "./Brand"

import EnglishNavList from "./list/EnglishNavList"
import PolishNavList from "./list/PolishNavList"

import SiteLanguage from "../../../helpers/siteLanguage"

import Icon from "./../../../assets/images/svg/phone.svg"

import LanguageSwitcher from "./../../language-switcher/language-switcher"

const ACFQuery = graphql`
  {
    wp {
      pageHeader {
        en {
          phoneText
          phoneNumber
          contactLink {
            url
            title
            target
          }
        }
        pl {
          phoneTextPl
          phoneNumberPl
          contactLinkPl {
            url
            title
            target
          }
        }
      }
    }
  }
`

const NavInner = styled.nav`
  position: fixed;
  width: 100vw;
  padding: 4.5rem 0 1.5rem;
  top: 0;

  z-index: 9980;

  transition: padding 400ms ease;

  &::after {
    display: block;
    position: absolute;
    content: "";

    width: 100%;
    height: 100%;
    z-index: -1;

    top: 0;
    left: 0;
    background-color: #fff;

    transform: translateY(-180px);
    transition: transform 1500ms var(--fancy-cubic-bezier);
    box-shadow: rgb(0 0 0 / 4%) 0px 0px 1px, rgb(0 0 0 / 6%) 0px 0px 2.9px,
      rgb(0 0 0 / 7%) 0px 0px 6.9px, rgb(0 0 0 / 11%) 0px 0px 23px;
  }

  &.sticky {
    padding: 1.5rem 0;
    z-index: 9999;

    &::after {
      transform: translateY(0);
    }

    @media ${maxD.mobileL} {
      padding: 1.3rem 0;
    }
  }
`

const NavButtons = styled.div`
  display: flex;
  align-items: center;
`

const NavPhone = styled(WideWrapper)`
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  padding-right: 0;
  z-index: 9990;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2rem;
`

const PhoneLine = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 0.85rem;
`

const PhoneIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;

  fill: var(--main-green);
`

const Nav = ({ pageContext, data }) => {
  const { isSticky } = useStickyElement()
  const [isMobileActive, toggleNavActivity] = useState(false)

  // I know, this way of doing multilingualism is not the best, but I was doing it 5 hours before leaving the company :c

  const acfData = useStaticQuery(ACFQuery)
  const enData = acfData.wp.pageHeader.en
  const plData = acfData.wp.pageHeader.pl

  let phoneText, phoneNumber, contactLink

  if (SiteLanguage() === "en") {
    phoneText = enData.phoneText
    phoneNumber = enData.phoneNumber
    contactLink = enData.contactLink
  } else {
    phoneText = plData.phoneTextPl
    phoneNumber = plData.phoneNumberPl
    contactLink = plData.contactLinkPl
  }

  return (
    <>
      {isMobileActive && <PageWithoutScroll />}
      <NavInner className={(isSticky || isMobileActive) && "sticky"}>
        <WideWrapper flex spaceBetween alignItems="c">
          <Brand />
          {SiteLanguage() === "en" ? (
            <EnglishNavList isActive={isMobileActive} />
          ) : (
            <PolishNavList isActive={isMobileActive} />
          )}
          <NavButtons>
            {contactLink && (
              <Button
                theme="orange"
                to={contactLink.url}
                target={contactLink.target || "_self"}
              >
                {contactLink.title}
              </Button>
            )}
            <HamburgerMenu
              handleHamburgerClick={() => toggleNavActivity(!isMobileActive)}
              isActive={isMobileActive}
            />
          </NavButtons>
        </WideWrapper>
      </NavInner>
      <NavPhone relative>
        <LanguageSwitcher pageContext={pageContext} />
        <PhoneLine>
          <PhoneIcon />
          {phoneText} &nbsp;
          {phoneNumber && (
            <UnderlinedLink
              href={`tel:${phoneNumber.replace(" ", "")}`}
              style={{ fontSize: "0.85rem" }}
              as="a"
            >
              {phoneNumber}
            </UnderlinedLink>
          )}
        </PhoneLine>
      </NavPhone>
    </>
  )
}

export default Nav
