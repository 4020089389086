import styled from "styled-components"
import { Link as LinkTo } from "gatsby"

import { maxD } from "../../../assets/styles/helpers/devices"

const buttonTheme = theme => {
  switch (theme) {
    case "green":
      return "var(--main-green)"
    case "orange":
      return "var(--orange)"
    case "white":
      return "#fff"
    default:
      return "var(--dark-gray)"
  }
}

const Button = styled(LinkTo)`
  --padding-vertical: 17px;
  --padding-horizontal: 30px;
  --border-radius: 30px;

  display: inline-flex;
  flex-shrink: 0;

  padding: var(--padding-vertical) var(--padding-horizontal);
  border-radius: var(--border-radius);

  border: 2px solid ${({ theme }) => buttonTheme(theme)};
  background-color: ${({ theme }) => buttonTheme(theme)};
  color: ${({ theme }) => (theme === "white" ? "var(--dark-gray)" : "#fff")};

  text-align: center;
  font-size: 0.8rem;

  transition: color 200ms, background-color 200ms;

  @media ${maxD.mobileL} {
    display: var(--display-on-mobile, inline-flex);
  }

  &:not(disabled):hover {
    color: ${({ theme }) => buttonTheme(theme)};
    background-color: ${({ theme }) =>
      theme === "white" ? "var(--dark-gray)" : "#fff"};
  }

  @media ${maxD.desktop} {
    padding: calc(var(--padding-vertical) * 0.96)
      calc(var(--padding-horizontal) * 0.96);
  }

  @media ${maxD.laptop} {
    padding: calc(var(--padding-vertical) * 0.85)
      calc(var(--padding-horizontal) * 0.85);
  }
`

export { Button }
